// src/component/section/updates/UpdateItem.jsx

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faInstagram,
    faTwitter,
    faTiktok,
    faDiscord,
    faReddit,
    faYoutube,
    faTelegram,
    faKickstarter, // Placeholder for Bluesky
} from '@fortawesome/free-brands-svg-icons';
import Navigate from '../../../../../util/Navigate';

const UpdateItem = ({ update, currentUserId, schedulerId }) => {
    const platforms = [
        { name: 'Facebook', icon: faFacebook, field: 'posts_to_facebook' },
        { name: 'Instagram', icon: faInstagram, field: 'posts_to_instagram' },
        { name: 'Twitter', icon: faTwitter, field: 'posts_to_twitter' },
        { name: 'TikTok', icon: faTiktok, field: 'posts_to_tiktok' },
        { name: 'Discord', icon: faDiscord, field: 'posts_to_discord' },
        { name: 'Reddit', icon: faReddit, field: 'posts_to_reddit' },
        { name: 'YouTube', icon: faYoutube, field: 'posts_to_youtube' },
        { name: 'Telegram', icon: faTelegram, field: 'posts_to_telegram' },
        { name: 'Bluesky', icon: faKickstarter, field: 'posts_to_bluesky' }, // Placeholder icon
    ];

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    const mediaUrl = update.media_url || (update.media && update.media.url);

    return (
        <div className="card mb-3">
            <div className="card-header d-flex justify-content-between align-items-center">
                <div>
                    {update.title && <h5 className='text-black'>{update.title}</h5>}
                    {update.update_type && <small className="text-muted">{update.update_type}</small>}
                </div>
                {currentUserId === update.user_id && (
                    <Link to={Navigate.schedulerUpdatesUpdatePage(schedulerId, update.id)} className="btn btn-sm btn-secondary">
                        Edit
                    </Link>
                )}
            </div>
            <div className="card-body">
                {update.content && (
                    <div dangerouslySetInnerHTML={createMarkup(update.content)} />
                )}
                {mediaUrl && (
                    <div className="mt-3">
                        {update.update_type === 'video' || (update.media && update.media.mime_type && update.media.mime_type.startsWith('video/')) ? (
                            <video controls style={{ maxWidth: '100%' }}>
                                <source src={mediaUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <img src={mediaUrl} alt="Update Media" style={{ maxWidth: '100%' }} />
                        )}
                    </div>
                )}
            </div>
            <div className="card-footer d-flex justify-content-between align-items-center">
                <div>
                    {update.user && (
                        <small className="text-muted">
                            Posted by {update.user.username}
                        </small>
                    )}
                </div>
                <div>
                    {platforms.map(platform => {
                        if (update[platform.field]) {
                            return (
                                <FontAwesomeIcon
                                    key={platform.name}
                                    icon={platform.icon}
                                    title={platform.name}
                                    className="ml-2"
                                />
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
        </div>
    );
};

export default UpdateItem;
