// src/pages/scheduler/scheduler_list_updates_page.jsx

import Glitch from 'glitch-javascript-sdk';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faInstagram,
    faTwitter,
    faTiktok,
    faDiscord,
    faReddit,
    faYoutube,
    faTelegram,
    faKickstarter, // Placeholder for Bluesky
} from '@fortawesome/free-brands-svg-icons';
import ScheduleNavbar from '../../component/section/schedulers/schedule_navbar';
import Breadcrumbs from '../../component/layout/breadcrumb';
import UpdateItem from '../../component/section/updates/update_item';

const SchedulerListUpdatesPage = () => {
    const [scheduler, setScheduler] = useState({});
    const [updates, setUpdates] = useState([]);
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [warningMessage, setWarningMessage] = useState(null);
    const [currentUserId, setCurrentUserId] = useState(null);

    const [filters, setFilters] = useState({
        type: '',
        socialPlatforms: {},
        order: 'desc',
    });

    const navigate = useNavigate();

    const platforms = [
        { name: 'Facebook', icon: faFacebook, field: 'posts_to_facebook' },
        { name: 'Instagram', icon: faInstagram, field: 'posts_to_instagram' },
        { name: 'Twitter', icon: faTwitter, field: 'posts_to_twitter' },
        { name: 'TikTok', icon: faTiktok, field: 'posts_to_tiktok' },
        { name: 'Discord', icon: faDiscord, field: 'posts_to_discord' },
        { name: 'Reddit', icon: faReddit, field: 'posts_to_reddit' },
        { name: 'YouTube', icon: faYoutube, field: 'posts_to_youtube' },
        { name: 'Telegram', icon: faTelegram, field: 'posts_to_telegram' },
        { name: 'Bluesky', icon: faKickstarter, field: 'posts_to_bluesky' }, // Placeholder icon
    ];

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            const currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        } else {
            setCurrentUserId(Glitch.util.Session.getID());
            fetchSchedulerAndUpdates();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, filters]);

    const fetchSchedulerAndUpdates = () => {
        setIsLoading(true);

        Glitch.api.Scheduler.getSchedule(id)
            .then(response => {
                setScheduler(response.data.data);

                const queryParams = {};

                if (filters.type) {
                    queryParams.type = filters.type;
                }

                platforms.forEach(platform => {
                    if (filters.socialPlatforms[platform.field] !== undefined && filters.socialPlatforms[platform.field] !== false) {
                        queryParams[platform.field] = filters.socialPlatforms[platform.field];
                    }
                });

                // Order by created_at
                queryParams.order_by = 'created_at';
                queryParams.order_direction = filters.order;

                Glitch.api.Scheduler.listUpdates(response.data.data.id, queryParams)
                    .then(res => {
                        setUpdates(res.data.data);
                        const authenticatedCount = platforms.filter(platform =>
                            platform.oauthField && response.data.data[platform.oauthField]
                        ).length;

                        if (authenticatedCount === 0) {
                            setWarningMessage(
                                "No accounts have been authenticated, and therefore no post will be scheduled. Please authenticate your social media accounts."
                            );
                        }
                        setIsLoading(false);
                    })
                    .catch(error => {
                        console.error(error);
                        setIsLoading(false);
                    });
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
            });
    };

    const handleFilterChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (name === 'type') {
            setFilters({
                ...filters,
                type: value,
            });
        } else if (name === 'order') {
            setFilters({
                ...filters,
                order: value,
            });
        } else {
            // Handle social platforms
            setFilters({
                ...filters,
                socialPlatforms: {
                    ...filters.socialPlatforms,
                    [name]: checked,
                },
            });
        }
    };

    const createMarkup = htmlContent => ({ __html: htmlContent });

    if (isLoading) {
        return (
            <>
                <PublisherHeader position={"relative"} />
                <div className="container my-5 text-center">
                    <Loading />
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
                <Breadcrumbs items={[
                    { name: 'Schedulers', link: Navigate.schedulerListPage() },
                    { name: scheduler?.name, link: Navigate.schedulerViewPage(scheduler?.id) },
                    { name: 'Updates', link: Navigate.schedulerUpdatesListPage(scheduler?.id) }
                ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title mt-3">{scheduler?.name || 'Scheduler'} Updates</h2>
                        <p className="lead">View a list of updates for {scheduler?.name}.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5">
                <ScheduleNavbar schedulerId={id} />
            </div>

            <div className="container my-5">
                {/* Filters */}
                <div className="card mb-4">
                    <div className="card-header">
                        <h4 className="text-black">Filters</h4>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="row">
                                {/* Type Filter */}
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="type">Update Type</label>
                                        <select
                                            className="form-control"
                                            id="type"
                                            name="type"
                                            value={filters.type}
                                            onChange={handleFilterChange}
                                        >
                                            <option value="">All Types</option>
                                            <option value="development_update">Development Update</option>
                                            <option value="link">Link</option>
                                            <option value="youtube_video">YouTube Video</option>
                                            <option value="image">Image</option>
                                            <option value="video">Video</option>
                                        </select>
                                    </div>
                                </div>

                                {/* Order */}
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="order">Order</label>
                                        <select
                                            className="form-control"
                                            id="order"
                                            name="order"
                                            value={filters.order}
                                            onChange={handleFilterChange}
                                        >
                                            <option value="desc">Newest First</option>
                                            <option value="asc">Oldest First</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* Social Platforms */}
                            <div className="form-group mt-3">
                                <label>Social Platforms</label>
                                <div className="row">
                                    {platforms.map(platform => (
                                        <div className="col-md-3" key={platform.field}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={platform.field}
                                                    name={platform.field}
                                                    checked={filters.socialPlatforms[platform.field] || false}
                                                    onChange={handleFilterChange}
                                                />
                                                <label className="form-check-label" htmlFor={platform.field}>
                                                    <FontAwesomeIcon icon={platform.icon} /> {platform.name}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* List of Updates */}
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h4 className="text-black">Updates</h4>
                        <Link to={Navigate.schedulerUpdatesCreatePage(scheduler.id)} className="btn btn-primary">
                            <i className="fas fa-plus-circle"></i>  Add New Update
                        </Link>
                    </div>
                    <div className="card-body">
                        {updates.length > 0 ? (
                            updates.map(update => (
                                <UpdateItem
                                    key={update.id}
                                    update={update}
                                    currentUserId={currentUserId}
                                    schedulerId={scheduler.id}
                                />
                            ))
                        ) : (
                            <p>No updates available.</p>
                        )}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default SchedulerListUpdatesPage;
